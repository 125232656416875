<template>
  <section v-if="branch">
    <Stack
      tag="div"
      gap="none"
      class="content-container py-sm sm:py-3xl px-xs sm:px-sm md:px-md lg:px-[96px] !gap-[120px]"
      direction="col"
      :breakpoints="{ direction: { md: 'row' } }"
    >
      <Stack
        tag="div"
        gap="2xl"
        justify="start"
        class="w-full flex-1"
        direction="col"
        :breakpoints="{ direction: { md: 'row' } }"
      >
        <BranchHours :branch="branch" class="w-full md:w-fit" />
        <BranchAddress :branch="branch" />
      </Stack>
      <div class="flex-1">
        <Heading tag="h2" size="sm" class="mb-md">What You'll find at {{ branch[0].name }}</Heading>

        <ul class="grid md:grid-cols-2 lg:grid-cols-3 gap-sm md:gap-xl">
          <Stack tag="li" gap="xs" align="center" v-for="feature in features(page)" :key="feature.title">
            <SvgIcon :name="useBloomreachIconMapping(String(feature.icon))" :height="64" :width="64" />
            <Text tag="p" size="sm" weight="bold">{{ feature.title }}</Text>
          </Stack>
        </ul>
      </div>
    </Stack>
  </section>
</template>

<script setup lang="ts">
import { BranchFeaturesProps, PageData } from "./branchDetailsTypes";
import useBloomreachIconMapping from "@/composables/useIconMappings";

const props = defineProps<BranchFeaturesProps>();

const { branch, page } = toRefs(props);

interface Feature {
  title: string;
  icon: {
    selectionValues: {
      key: string;
    }[];
  };
}

const content = (page: PageData) => {
  return page.getDocument()?.getData();
};

const features = (page: PageData): Feature[] => {
  return (content(page)?.features?.items || []).map((feature: Feature) => ({
    title: feature.title,
    icon: feature.icon?.selectionValues?.[0]?.["key"],
  }));
};
</script>
