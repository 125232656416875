<template>
  <section v-if="branch && isNewBranch(branch[0])">
    <SvgIcon name="coming-soon" :width="275" :height="196" class="mx-auto" />
  </section>

  <section v-else>
    <Stack gap="xs" align="center" class="mb-md">
      <Icon name="time-outline" :size="16" />
      <Heading tag="h2" size="sm">Opening Hours</Heading>
    </Stack>

    <Text id="branchStatus" tag="p" weight="bold" size="md" :class="['mb-xs', openStatusStyle]">{{
      isStoreOpenText
    }}</Text>

    <div v-for="(workingTime, index) in reorderedBranchWorkingTimes" :key="`branch-workingTime-${index}`" class="flex">
      <Text tag="p" :weight="(workingTime as any)?.day === currentWeekDayName ? 'bold' : 'normal'" class="capitalize">
        {{ (workingTime as any)?.day }}
      </Text>
      <Text
        v-if="(workingTime as any).time"
        tag="p"
        class="whitespace-nowrap"
        :weight="(workingTime as any)?.day === currentWeekDayName ? 'bold' : 'normal'"
      >
        {{ (workingTime as any)?.time?.opens_at }} - {{ (workingTime as any).time?.closes_at }}
      </Text>
      <Text v-else tag="p" :weight="(workingTime as any)?.day === currentWeekDayName ? 'bold' : 'normal'">Closed</Text>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { BranchHoursProps } from "./branchDetailsTypes";
import { reorder } from "mkm-avengers";
import dayjs from "dayjs";

const props = defineProps<BranchHoursProps>();
const { branch } = toRefs(props);

const { isStoreOpenText, openStatusStyle } = useBranchWorkingTime(branch.value?.[0] || ({} as Branch));
const { isNewBranch } = useBranches();

const currentDay = dayjs().day();
// Convert the branch working time from object to an array with key - the name of the week day
// to be able to reorder the week days -->
// putting today (the current day when the user opens the page) at first position
type WorkingTimes = {
  day: string;
  time: {
    opens_at: string;
    closes_at: string;
  };
}[];

const branchWorkingTimes = Object.entries(branch?.value?.[0]?.working_times as any).map(([day, time]) => ({
  day,
  time,
}));

// Reorder the branch working times array and putting today's data object first in the array
const reorderedBranchWorkingTimes = reorder(branchWorkingTimes, currentDay - 1) as WorkingTimes[];

const currentWeekDayName = computed(() => {
  return dayjs().format("dddd").toLocaleLowerCase();
});
</script>
